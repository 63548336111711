<template>

</template>

<script>
import { BCard, BCardText, BLink, BImg, BRow } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BImg,
    BRow,
  },
}
</script>

<style>

</style>
